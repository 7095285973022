import { Typography } from "@mui/material"
import { format, parse, parseISO } from "date-fns"

export const getValueText = (value: number | string) => {
  if (typeof value === "string") {
    return value
  }
  return value > 999999 ? `${(value / 1000000).toFixed(1)}M` : value > 999 ? `${(value / 1000).toFixed(1)}K` : value
}

export const getFilterText = (filter: any) => {
  if (!filter) return ""
  const stringifiedFilters = Object.keys(filter).map(
    (key, idx) => `${key}=${filter[key]}${Object.keys(filter).length - 1 === idx ? "" : "&"}`
  )
  let endString = ""
  for (const filterString of stringifiedFilters) {
    endString = endString.concat(filterString)
  }
  return `&${endString}`
}

export const getDate = (date: string) => {
  try {
    return format(parse(date, "yyyy-LL-dd", new Date()), "dd/LL/yyyy")
  } catch (e) {
    try {
      return format(parseISO(date), "dd/LL/yyyy")
    } catch (e) {
      return date
    }
  }
}

export const replaceSoundstripeText = (text: string) => {
  if (!text) {
    return text
  }
  
  if (text.includes("Soundstripe")) {
    const updatedText = text
      .replace("Soundstripe since joining in 2016", "our catalog")
      .replace("Soundstripe in 2017", "our catalog")
      .replace("Soundstripe", "Simena")
    return updatedText
  }
  return text
}